import {
  InquiryRequest,
  isAllInquiryParams,
  useUploadInquiry,
} from '@/API/serviceAPI'
import PhoneInput from '@/components/form/PhoneInput'
import UserInfoConsent from '@/components/user/UserInfoConsent'
import {
  checkEmailValidation,
  checkPhoneNumberVaildation,
  ToastUtil,
} from '@/util/util'
import {
  chakra,
  Flex,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  useMediaQuery,
  Button,
  useToast,
  UseToastOptions,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

const Contact = () => {
  const toast = useToast()
  const [isMobile] = useMediaQuery('(max-width: 768px)')
  const [isConsent, setIsConsent] = useState<boolean>(false)

  const { mutate: uploadInquiry, isSuccess: isUploadInquirySuccess } =
    useUploadInquiry()

  const toggleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const target = e.target as HTMLFormElement
    const inputs = Array.from(target.elements) as Array<HTMLInputElement>
    const detailTextArea = target.elements[5] as HTMLTextAreaElement
    const params: Partial<InquiryRequest> = {}

    inputs.map((input) => {
      if (input.tagName === 'INPUT' && !input.id.includes('field')) {
        const id = input.id as keyof InquiryRequest
        params[id] = input.value
      }
    })

    if (detailTextArea.value) {
      params['detail'] = detailTextArea.value
    } else {
      toast(
        ToastUtil(
          '상세 내용 기재 필요',
          'warning',
          '상세 내용을 기재하여 문의해 주세요.',
        ) as UseToastOptions,
      )
      return
    }

    if (isAllInquiryParams(params)) {
      const isVaildEmail = checkEmailValidation(params.email)
      const isVaildPhoneNumber = checkPhoneNumberVaildation(params.phone)
      if (isVaildEmail && isVaildPhoneNumber && isConsent) {
        uploadInquiry(params)
      } else if (!isVaildEmail) {
        toast(
          ToastUtil(
            '이메일 주소 형식 오류',
            'warning',
            '올바른 이메일 주소를 입력해주세요.',
          ) as UseToastOptions,
        )
      } else if (!isVaildPhoneNumber) {
        toast(
          ToastUtil(
            '전화번호 형식 오류',
            'warning',
            '올바른 전화번호를 입력해 주세요.',
          ) as UseToastOptions,
        )
      } else if (!isConsent) {
        toast(
          ToastUtil(
            '개인정보 이용 동의 필요',
            'warning',
            '개인정보 수집 및 이용에 대한 동의가 필요합니다.',
          ) as UseToastOptions,
        )
      } else {
        toast(
          ToastUtil(
            '공란 확인 필요',
            'warning',
            '비어있는 란이 있습니다. 내용을 모두 기재한 후 제출해 주세요.',
          ) as UseToastOptions,
        )
      }
    }
  }
  const returnInputForm = () => {
    const eachFormId = [
      {
        name: '기업명',
        id: 'company_name',
      },
      {
        name: '이름',
        id: 'contact_name',
      },
      { name: '이메일', id: 'email' },
      { name: '전화번호', id: 'phone' },
      { name: '문의 제목', id: 'title' },
    ]
    return eachFormId.map((eachData, idx) => {
      return (
        <Flex
          flex={1}
          minWidth={isMobile ? 'auto' : '450px'}
          flexDirection="column"
          key={idx}
          gap={isMobile ? 2 : 4}
          id={`input-group`}
        >
          <FormLabel fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>
            {eachData.name}
          </FormLabel>
          {eachData.id === 'phone' ? 
            <PhoneInput
              isMobile={isMobile}
              id="phone"
            />
                :
              <Input
                id={eachData.id}
                w="auto"
                h={isMobile ? '50px' : '60px'}
                bgColor="#F7F7F7"
                borderRadius={0}
                border="none"
                placeholder={eachData.id === 'phone' ? '010-xxxx-xxxx' : ''}
              />
            }
        </Flex>
      )
    })
  }

  useEffect(() => {
    if (isUploadInquirySuccess) {
      toast(
        ToastUtil(
          '문의 업로드 성공',
          'success',
          '작성하신 문의 내용 업로드가 완료 되었습니다.',
        ) as UseToastOptions,
      )
    }
  }, [isUploadInquirySuccess])

  return (
    <Flex
      id="contact"
      py={isMobile ? 10 : 16}
      px={isMobile ? 6 : '10%'}
      minW="100vw"
      flexDirection={'column'}
    >
      <Flex
        flexDirection="column"
        gap={isMobile ? 4 : 6}
        justifyContent="flex-start"
        w="full"
        maxW={'full'}
        py={6}
      >
        <Flex flexDirection="column" gap={4} pb={2} alignItems={'center'}>
          <Heading color="#004A8D" fontSize={isMobile ? '34px' : '50px'}>
            Contact us
          </Heading>
          <Text
            fontSize={isMobile ? 'md' : '22px'}
            color="#787E88"
            fontWeight={500}
            textAlign="center"
          >
            우리는 어떤 문의에도 성심껏 답변드립니다. <br />
            최상의 서비스로 고객 만족을 위해 노력하고 있습니다.
          </Text>
        </Flex>
      </Flex>
      <Flex w="full" py={8} overflowY="auto">
        <chakra.form onSubmit={toggleSubmit}>
          <FormControl
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            gap={8}
          >
            {returnInputForm()}
            <Flex flex="full" w="full" flexDirection="column" gap={2}>
              <FormLabel fontSize={isMobile ? 'md' : '18px'} fontWeight={500}>
                상세 내용
              </FormLabel>
              <Textarea
                id="detail"
                minH={isMobile ? '300px' : '185px'}
                bgColor="#F7F7F7"
                borderRadius={0}
                border="none"
              />
            </Flex>
            <UserInfoConsent
              isConsent={isConsent}
              setIsConsent={setIsConsent}
            />
            <Flex w="full" justifyContent="center" my={4}>
              <Button
                type="submit"
                w="full"
                h={isMobile ? '50px' : '60px'}
                bgColor="#0D3A65"
                borderRadius={0}
              >
                문의하기
              </Button>
            </Flex>
          </FormControl>
        </chakra.form>
      </Flex>
    </Flex>
  )
}

export default Contact
